import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://036f1afedaee26c438ee0f19da9a1d78@o4504770655092736.ingest.us.sentry.io/4508282141999104",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});